"use client"; // error components must be Client Components

import Bugsnag from "@bugsnag/js";
import { Button, Text } from "@packages/ui";
import {
  numericPercentages,
  numericPixels,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import { useEffect } from "react";

const styles = stylex.create({
  errorContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: numericPixels[360],
    justifyContent: "center",
    maxHeight: numericPercentages[100],
  },
  title: {
    marginBottom: spacing.xl,
  },
});

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Bugsnag.notify(error);
  }, [error]);

  return (
    <div {...stylex.props(styles.errorContainer)}>
      <Text size="m" type="headline" styleXArray={[styles.title]}>
        Something went wrong!
      </Text>
      <Button
        // attempt to recover by trying to re-render the segment
        onClick={() => reset()}
      >
        Try again
      </Button>
    </div>
  );
}
